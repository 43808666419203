


























































































































































































































































import Vue from 'vue';
import { Component, ModelSync, Prop, Watch } from 'vue-property-decorator';

import {
  ADDITIONAL_HEADERS_BANKING_PROFILE,
  BASE_BLACK_COLOR,
  BASE_COLOR,
  DEFAULT_HEADERS_BANKING_PROFILE,
  SELECTED_COLOR,
} from '@/utils/constants';

import { mapActions, mapGetters } from 'vuex';
import { timePeriodOptions } from '@/utils/helpers';

interface OptionsData {
  standortlaender: string[] | undefined;
  schwerpunkte: string[] | undefined;
}

interface Userdata {
  isDemoUser: boolean;
  _id: string;
  email: string;
  givenName: string;
  familyName: string;
}

@Component({
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      fetchFilteredBankingProfile: 'bankingProfile/fetchFilteredBankingProfile',
    }),
  },
})
export default class FilterBankingProfile extends Vue {
  @ModelSync('locationCountries', 'changeLocationCountries')
  locationCountriesValue!: Array<string>;
  @ModelSync('keyAreas', 'changeKeyAreas')
  keyAreasValue!: String;
  @ModelSync('region', 'changeRegion') regionValue!: string[];
  @ModelSync('stockExchangeListed', 'changeStockExchangeListed', {
    type: Boolean,
  })
  stockExchangeListedValue!: Boolean;
  @ModelSync('selectedHeaders', 'changeSelectedHeaders', {
    type: Array,
  })
  selectedHeadersValues!: Array<String>;

  @Prop({ default: [] }) stringOptions!: OptionsData;
  @Prop({ default: [] }) rows!: Array<Object>;
  @Prop() loading!: Boolean;
  @Prop() originalRows!: Array<Object>;
  @Prop() globalLoading?: Boolean;
  @Prop() period?: string[];

  color = BASE_BLACK_COLOR;
  selectedColor = SELECTED_COLOR;
  baseColor = BASE_COLOR;
  regionOptions = [
    'dach',
    'europa',
    'eu',
    'benelux',
    'brics',
    'afrika',
    'asien',
    'australien und ozeanien',
    'amerikas',
  ];
  chipsHeaders = ADDITIONAL_HEADERS_BANKING_PROFILE;
  originalHeaders = [...DEFAULT_HEADERS_BANKING_PROFILE];
  keyAreas!: String;
  user!: Userdata;

  fetchFilteredBankingProfile!: (payload: any) => Promise<Object>;

  @Watch('$route', { immediate: true }) fetchData() {
    if (this.$router.currentRoute.name === 'bankingProfileNewReport') {
      setTimeout(() => {
        this.filterRows();
      }, 200);
    }
  }

  handleTagClose(e) {
    const filtered = this.locationCountriesValue.filter((item) => item !== e);
    this.$emit('changeLocationCountries', filtered);
  }
  get chunks() {
    const arr: Array<object[]> = [];
    for (let i = 0; i < this.chipsHeaders.length; i += 4) {
      arr.push(this.chipsHeaders.slice(i, i + 4));
    }
    return arr;
  }

  get isDisabled() {
    return !!this.user?.isDemoUser;
  }
  get periodValue() {
    return this.period || [];
  }
  set periodValue(val: string[]) {
    if (val.length <= 4) {
      this.$emit('changePeriod', val);
    }
    if (!val.length) {
      this.$emit('changePeriod', timePeriodOptions().defaultSelected);
    }
  }
  get periodOptions() {
    const { years } = timePeriodOptions();
    return years;
  }
  get allCountriesSelected() {
    return this.regionValue.length === this.regionOptions.length;
  }

  handleChangeAllSelected() {
    this.$emit('changeAllCountriesSelected', !this.allCountriesSelected);
  }
  get allSelected() {
    return this.selectedHeadersValues.length === this.chipsHeaders.length;
  }
  set allSelected(val) {
    this.$emit('allSelected', val);
  }

  resetSearch() {
    (this.$refs as any).search.lazySearch = '';
  }
  resetSearchKeyAreas() {
    (this.$refs as any).search2.lazySearch = '';
  }

  async filterRows() {
    const arr: Object[] = [];
    const allHeaders: Array<Object> = [];
    this.selectedHeadersValues.forEach((header) => {
      const search = this.chipsHeaders.find(
        (item) => (item as any).value === header
      );
      if (search) {
        arr.push(search);
      }
    });
    if (arr.length) {
      this.originalHeaders.forEach((header) => {
        allHeaders.push(header);
      });
      arr.forEach((header) => {
        allHeaders.push(header);
      });
    }
    const params = {
      zeitraum: this.periodValue,
      standorte: this.stringOptions.standortlaender?.length
        ? this.locationCountriesValue
        : '',
      schwerpunkte: this.stringOptions.schwerpunkte?.length
        ? this.keyAreas
        : '',
      boersennotiert: this.stockExchangeListedValue,
    };
    const regions = this.regionValue.map((item) => item.toUpperCase());

    if (regions.length !== 9) {
      Object.assign(params, { regionen: regions }, {});
    }
    const payload: any = {};

    Object.keys(params).forEach((key) => {
      if (params[key] && typeof params[key] === 'boolean') {
        Object.assign(payload, { [key]: params[key] });
      }
      if (
        params[key] &&
        typeof params[key] !== 'boolean' &&
        params[key].length
      ) {
        Object.assign(payload, { [key]: params[key] });
      }
    });
    payload.headers = allHeaders.length
      ? [...new Set(allHeaders)]
      : this.originalHeaders;
    this.$emit('changeLoading', true);
    try {
      const rows = await this.fetchFilteredBankingProfile(payload);
      const defaultFilteredRows = JSON.parse(
        localStorage.getItem('defaultFilteredRows') || '[]'
      );
      if (!defaultFilteredRows.length) {
        localStorage.setItem('defaultFilteredRows', JSON.stringify(rows));
      }
      if (arr.length) {
        this.$emit('changeHeaders', [...new Set(allHeaders)]);
      } else {
        this.$emit('changeHeaders', this.originalHeaders);
      }
      this.$emit('changeRows', rows);
      this.$emit('changeLoading', false);
    } catch (e) {
      this.$emit('changeLoading', false);
    }

    if (arr.length) {
      const result: Array<Object> = [];
      this.originalHeaders.forEach((header) => {
        result.push(header);
      });
      arr.forEach((header) => {
        result.push(header);
      });
      this.$emit('changeHeaders', [...new Set(result)]);
    } else {
      this.$emit('changeHeaders', this.originalHeaders);
    }
    this.$emit('changeLoading', false);
  }
}
